import config from '~/config';

export const generateWebformEndpoint = ({
  domain,
  orgId
}: {
  domain: string;
  orgId: string;
}) => {
  if (!domain || !orgId) {
    throw new Error('Both domain and orgId are required.');
  }

  const url = new URL(domain);
  const params = new URLSearchParams({
    encoding: config.SALESFORCE_ACCEPTED_CHARACTER_SET,
    orgId
  });

  url.search = params.toString();

  return url.toString();
};
