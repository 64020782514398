























import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { FooterLink } from '~/types/cms/Footer/FooterLink/FooterLink';
import { instanceOfFooterLink } from '~/types/cms/Footer/FooterLink/instanceOfFooterLink';
import Link from '~/components/atoms/Link/Link.vue';
import { LinkType } from '~/types/components/Link/LinkType';

export default defineComponent({
  name: 'FooterLinkColumn',
  components: {
    Link
  },
  props: {
    title: {
      type: String,
      required: true
    },
    links: {
      type: Array as PropType<FooterLink[]>,
      required: true,
      validator: (links: FooterLink[]) => {
        if (links.length === 0) {
          return false;
        }
        return links.every(link => instanceOfFooterLink(link));
      }
    }
  },
  setup() {
    return {
      LinkType
    };
  }
});
