import { getServerCacheKey } from 'nuxt-multi-cache/client';
import { StringBoolean } from '~/types/StringBoolean';
import { COMPONENT_CACHE_PREFIX } from '~/constants/caching';

interface CacheParams {
  componentCaching: string;
  topLevelDomain: string;
  languageAndCountry: string;
}

const getComponentCacheKey = ({
  componentCaching,
  topLevelDomain,
  languageAndCountry
}: CacheParams): string | boolean => {
  if (componentCaching === StringBoolean.false) {
    return false;
  }
  const key = `${COMPONENT_CACHE_PREFIX}${languageAndCountry}`;
  return getServerCacheKey(key, [COMPONENT_CACHE_PREFIX, `${COMPONENT_CACHE_PREFIX}${topLevelDomain}`]);
};

export default getComponentCacheKey;
