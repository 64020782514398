



















































































import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import VueI18n from 'vue-i18n/types';
import SpinnerLoader from '~/components/atoms/SpinnerLoader/SpinnerLoader.vue';

export default defineComponent({
  name: 'PrimaryInput',
  components: {
    SpinnerLoader,
    SfInput
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String as PropType<VueI18n.TranslateResult>,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    title: {
      type: String as PropType<VueI18n.TranslateResult>,
      default: ''
    },
    hasShowPassword: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    successMessage: {
      type: String,
      default: ''
    },
    warningMessage: {
      type: String,
      default: ''
    },
    usageTip: {
      type: String,
      default: ''
    },
    valid: {
      type: Boolean,
      default: true
    }
  }
});
