








import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';

export default defineComponent({
  name: 'SpinnerLoader',
  components: {
    SfLoader
  },
  props: {
    size: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const loaderStyle = computed(() =>
      props.size && `height: ${props.size}; width: ${props.size};`
    );

    return {
      loaderStyle
    };
  }
});
